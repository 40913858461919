import { http } from './connector'

export function scripted(body: FormData, isWord: boolean = false) {
  if (isWord) {
    return http.post('/performance-tool/analysis/word', body)
  }

  return http.post('/performance-tool/analysis/sentence', body)
}

export function unscripted(body: FormData) {
  return http.post('/performance-tool/analysis/open-question', body)
}

export function practice(body: FormData) {
  return http.post('/performance-tool/analysis/practice', body)
}
