import { Audios } from '@/types/audios'
import Select, { type ItemData } from '@components/action/select'
import { useAudioPlayer } from '@hooks'
import { PlayIcon } from '@icons'

export function PreviousRecordsSelect({ records, mode, onChange }: { records: Audios.FormattedData[]; mode: string; onChange: (value: string) => void }) {
  if (records.length === 0 || mode === 'text') {
    return null
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <div
        after="or use a previous recording"
        className={'relative border border-neutral-03 after:content-[attr(after)] after:absolute after:w-fit after:left-1/2 after:-translate-y-1/2 after:top-1/2 after:-translate-x-1/2 after:block after:overflow-visible after:z-10 after:px-4 after:bg-white after:text-neutral-04 after:text-center'}
      ></div>

      <Select
        component={ListItem}
        data={records}
        onChange={(e) => {
          onChange(e.toString())
        }}
      />
    </div>
  )
}

function ListItem({ label, date, audioUrl, value, onClick }: ItemData & { onClick?: () => void }) {
  const audioPlayer = useAudioPlayer(audioUrl)

  if (value === '') {
    return null
  }

  return (
    <div
      className="flex gap-8 items-center p-3 hover:bg-neutral-50"
      key={label}
    >
      <button
        className="w-8 h-8 hover:scale-150 transition-all duration-500"
        onClick={() => audioPlayer.play()}
      >
        <PlayIcon color="#80CAFF" />
      </button>

      <a
        className="flex flex-col flex-1 w-1/2 text-left"
        onClick={() => onClick?.()}
      >
        <span>{date}</span>
        <label className="text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer">{label}</label>
      </a>

      <span className="self-start">{audioPlayer.duration}</span>
    </div>
  )
}
