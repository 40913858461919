import { useState, useEffect } from 'react'

export function useAudioPlayer(_audioUrl?: string, range?: { start: number; end: number }) {
  const [audioUrl, setAudioUrl] = useState<string | null>(_audioUrl ?? null)
  const [duration, setDuration] = useState('0:00')
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)

  useEffect(() => {
    audio?.addEventListener('canplaythrough', () => {
      if (audio.duration === Infinity || !audio.duration) {
        audio.currentTime = 100000000

        setTimeout(() => {
          setDuration(parseDuration(audio.duration))
        }, 100)

        audio.currentTime = 0
        return
      }

      if (range && (audio.currentTime < range.start || audio.currentTime > range.end)) {
        audio.currentTime = range.start
      }

      setDuration(parseDuration(audio.duration))
    })

    audio?.addEventListener('ended', () => {
      setIsPlaying(false)
    })

    audio?.addEventListener('timeupdate', () => {
      if (!audio.duration || audio.duration === Infinity) {
        setProgress(0)
        return
      }

      const _progress = Math.floor((audio.currentTime / audio.duration) * 100)
      setProgress(_progress)
    })

    return () => {
      audio?.removeEventListener('canplaythrough', () => {})
      audio?.removeEventListener('ended', () => {})
      audio?.removeEventListener('timeupdate', () => {})
    }
  }, [audio])

  useEffect(() => {
    setDuration('0:00')

    if (!audioUrl) {
      setAudio(null)
    } else {
      const _audio = new Audio(audioUrl)
      setAudio(_audio)
    }
  }, [audioUrl])

  function parseDuration(duration: number) {
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration % 60)

    return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  function play() {
    if (!audio?.play) return

    audio.play()
    setIsPlaying(true)
  }

  function pause() {
    audio?.pause()
    setIsPlaying(false)
  }

  return {
    play,
    pause,
    duration,
    setAudioUrl,
    audioUrl,
    isPlaying,
    progress,
  }
}
