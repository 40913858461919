import { useRef } from 'react'
import Button from '@components/action/button'
import { PlusIcon } from '@icons'

export function LoadAudioButton({ onSelectedFile }: { onSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
  const fileRef = useRef<HTMLInputElement>(null)

  function onSelectFile() {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  return (
    <>
      <Button
        type="subtle"
        onClick={onSelectFile}
      >
        <span>Select audio file</span>
        <PlusIcon />
      </Button>

      <input
        accept=".mp3, .wav"
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={onSelectedFile}
      />
    </>
  )
}
