import { useRef } from 'react'
import Button from '@components/action/button'
import { PlusIcon, ChevronIcon } from '@icons'

export function LoadFileButton({ mode, onSelectedFile, fileLoaded }: { mode: string; onSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void; fileLoaded: boolean }) {
  if (mode !== 'text') {
    return null
  }

  const fileRef = useRef<HTMLInputElement>(null)

  function onSelectFile() {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  return (
    <>
      <Button
        type="subtle"
        onClick={onSelectFile}
      >
        <span>Load content from file</span>
        {fileLoaded ? <ChevronIcon direction="down" /> : <PlusIcon />}
      </Button>

      <input
        accept=".txt"
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={onSelectedFile}
      />
    </>
  )
}
